import React, {ChangeEvent, KeyboardEvent} from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'

interface SearchBoxProps {
	placeholder?: string
	initialValue?: string
	handleSearch: (searchValue: string) => void
	customWidthClass?: string
}

export const NewsSearchBox = (props: SearchBoxProps) => {
	const [fieldValue, setFieldValue] = React.useState(props.initialValue ?? '')

	function handleOnKeyUp(event: KeyboardEvent<HTMLInputElement>) {
		if (event.code === 'Escape') {
			setFieldValue('')
		} else if (event.code === 'Enter' || event.key === 'Enter') {
			// event.key is for mobile
			event.preventDefault()
			props.handleSearch(fieldValue)
		}
	}

	function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
		setFieldValue(event.target.value)
		if (event.target.value === '') {
			props.handleSearch('')
		}
	}

	return (
		<div className="group relative w-full">
			<div role="search">
				<div className="relative">
					<input
						id="search"
						name="search"
						value={fieldValue}
						onChange={handleOnChange}
						onKeyUp={handleOnKeyUp}
						className={cx(
							'border border-gray-300 bg-white text-gray-700',
							'hover:border-gray-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500',
							'relative w-full cursor-default rounded-md py-2 pl-3 pr-10 text-left shadow-sm',
							props.customWidthClass ?? 'sm:w-[384px]',
						)}
						placeholder={props.placeholder}
						type="search"
					/>
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
						<Icon
							className="h-5 w-5 text-gray-500"
							icon="search"
							aria-hidden="true"
						/>
					</span>
				</div>
			</div>
		</div>
	)
}
