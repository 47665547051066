import React from 'react'
import {Icon} from '~/blocks/Icon'
import {cx} from '~/utils/utils'

interface NewsListGridViewButtonsParams {
	gridView: boolean
	setGridView: (gridView: boolean) => void
}

export function NewsListGridViewButtons(props: NewsListGridViewButtonsParams) {
	return (
		<div className="inline-flex">
			{/* List button */}
			<button
				className={cx(
					!props.gridView ? 'border-r-2 border-blue-700 bg-white text-blue-700' : 'border-r-0 border-gray-300 bg-transparent text-gray-600',
					'inline-flex cursor-pointer items-center gap-[8px] rounded-l-[8px] border-2 px-[11px] py-[7px] text-xs font-medium leading-4 focus:outline-none',
				)}
				onClick={() => props.setGridView(false)}
			>
				<Icon
					icon="menu"
					className="h-[18px]"
				/>
				List
			</button>

			{/* Grid button */}
			<button
				className={cx(
					props.gridView ? 'border-l-2 border-blue-700 bg-white text-blue-700' : 'border-l-0 border-gray-300 bg-transparent text-gray-600',
					'inline-flex cursor-pointer items-center gap-[8px] rounded-r-[8px] border-2 px-[11px] py-[7px] text-xs font-medium leading-4 focus:outline-none',
				)}
				onClick={() => props.setGridView(true)}
			>
				<Icon
					icon="grid"
					className="h-[16px]"
				/>
				Grid
			</button>
		</div>
	)
}
