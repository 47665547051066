import '@total-typescript/ts-reset/filter-boolean'
import {initAccordion} from '~/blocks/Accordion'
import {initArticle} from '~/blocks/Article'
import {initBlockListing} from '~/blocks/BlockListing'
import {initButton} from '~/blocks/Button'
import {initChecklist} from '~/blocks/Checklist'
import {initContactCards} from '~/blocks/ContactCards'
import {initContactEmailPhone} from '~/blocks/ContactEmailPhone'
import {initEmbeddedMedia} from '~/blocks/EmbeddedMedia'
import {initEvent} from '~/blocks/Event'
import {initFigure} from '~/blocks/Figure'
import {initFilteredListing} from '~/blocks/FilteredListing'
import {initForm} from '~/blocks/Form'
import {initFormSelect} from '~/blocks/FormSelect'
import {initGallery} from '~/blocks/Gallery'
import {initHeader} from '~/blocks/Header'
import {initHeading} from '~/blocks/Heading'
import {initHeroBanner} from '~/blocks/HeroBanner'
import {initHorizontalTabs} from '~/blocks/HorizontalTabs'
import {initIcon} from '~/blocks/Icon'
import {initJumpNav} from '~/blocks/JumpNav'
import {initLinkListing} from '~/blocks/LinkListing'
import {initListing} from '~/blocks/Listing'
import {initLoadingAnimation} from '~/blocks/LoadingAnimation'
import {initModalUplift} from '~/blocks/ModalUplift'
import {initNews} from '~/blocks/News'
import {initNewsArticle} from '~/blocks/NewsArticle'
import {initNotice} from '~/blocks/Notice'
import {initPathfinder} from '~/blocks/Pathfinder'
import {initPeople} from '~/blocks/People'
import {initResearchEvents} from '~/blocks/ResearchEvents'
import {initRightSidebar} from '~/blocks/RightSidebar'
import {initSearch} from '~/blocks/Search'
import {initSearchPagination} from '~/blocks/SearchPagination'
import {initSearchResults} from '~/blocks/SearchResults'
import {initTable} from '~/blocks/Table'
import {initText} from '~/blocks/Text'
import {initTimeline} from '~/blocks/Timeline'
import {initGiftGovernanceRecords} from '~/pages/giftGovernanceRecords/GiftGovernanceRecordsPage'
import {initStaffNewsArticlePage} from '~/pages/news/StaffNewsArticlePage'
import {initStaffNewsIssuePage} from '~/pages/news/StaffNewsIssuePage'
import {initStaffNewsPage} from '~/pages/news/StaffNewsPage'
import {initResearchGatewaySearchPage} from '~/pages/search/ResearchGatewaySearchPage'
import {initSearchPage} from '~/pages/search/SearchPage'
import {initSelfGuidanceToolPage} from '~/pages/selfGuidanceTool/SelfGuidanceToolPage'

import {initBanner} from './blocks/Banner'
import {initCardGrid} from './blocks/CardGrid'
import {initCode} from './blocks/Code'
import {initContactInformation} from './blocks/ContactInformation'
import {initEmbedSurvey} from './blocks/EmbedSurvey'
import {initFrontpageNews} from './blocks/FrontpageNews'
import {initGrid} from './blocks/Grid'
import {initHighlightedTopics} from './blocks/HighlightedTopics'
import {initLinkRow} from './blocks/LinkRow'
import {initVerticalTabs} from './blocks/VerticalTabs'
import {initGlobalFooter} from './global/footer/Footer'
import {initGlobalHeader} from './global/header/Header'
import './staff-ui.css'
import { initNewsRecords } from './pages/news/NewsRecordsPage'
import { initNewsDetails } from './pages/news/NewsDetailsPage'

document.addEventListener('DOMContentLoaded', () => {
	setupComponents()
	setupInterceptingXhr()

	document.documentElement.classList.remove('no-js')
})

function setupComponents() {
	/* Code has to be first so that the example code doesn't get uplifted */
	initCode()

	/* Pages */
	initSearchPage()
	initResearchGatewaySearchPage()
	initStaffNewsPage()
	initStaffNewsIssuePage()
	initStaffNewsArticlePage()
	initSelfGuidanceToolPage()
	initGiftGovernanceRecords()
	initNewsRecords()
	initNewsDetails()

	/* Components */
	initHeroBanner()
	initLinkListing()
	initArticle()
	initNewsArticle()
	initGrid()
	initHorizontalTabs()
	initBanner()
	initLinkRow()
	initCardGrid()
	initVerticalTabs()
	initHighlightedTopics()
	initContactInformation()
	initFrontpageNews()
	initEmbedSurvey()
	initListing()
	initHeading()
	initPathfinder()
	initFigure()
	initText()
	initRightSidebar()
	initAccordion()
	initButton()
	initTimeline()
	initBlockListing()
	initTable()
	initForm()
	initFormSelect()
	initHeader()
	initFilteredListing()
	initModalUplift()
	initEmbeddedMedia()
	initSearch()
	initSearchResults()
	initSearchPagination()
	initContactCards()
	initContactEmailPhone()
	initPeople()
	initNews()
	initGallery()
	initEvent()
	initLoadingAnimation()
	initChecklist()
	initIcon()
	initResearchEvents()

	initJumpNav() // must be last

	/* Global */
	initGlobalHeader()
	initGlobalFooter()

	initNotice() // Notice must come after Header so flash notices can be mounted under it
}

function setupInterceptingXhr() {
	const send = XMLHttpRequest.prototype.send
	XMLHttpRequest.prototype.send = function (data) {
		this.addEventListener(
			'loadend',
			() => {
				if (this.responseURL.startsWith('https://staff.unimelb.edu.au/staff-news/submit')) {
					//blockUom()
					setupComponents()
					//blockUom()
				}
			},
			false,
		)
		send.call(this, data)
	}
}
