import {Tab} from '@headlessui/react'
import React, {Fragment, useState} from 'react'
import {Card, CardProps} from '~/cards/Card'
import {getSectionProps, SectionProps, SectionWrapper} from '~/components/SectionWrapper'
import {Select} from '~/components/Select'
import {queryHref, queryTextContent} from '~/utils/query'
import {render} from '~/utils/render'
import {cx, getUrlParam, setUrlParam} from '~/utils/utils'
import './VerticalTabs.css'

interface Tab {
	title?: string
	shortTitle?: string
	description?: string
	cards?: CardProps[]
	paragraphs?: string[]
	body?: string
}

interface VerticalTabsProps extends SectionProps {
	inlineHeaderHtml?: string
	isLegacy?: boolean
	tabs: Tab[]
	headerInWithTabs?: boolean
	defaultIndex?: number
}

export function initVerticalTabs() {
	document.querySelectorAll<HTMLElement>('.sh-vertical-tabs, .sh-tab-grouped-links, .block-vertical-tabs, .block-tab-grouped-links').forEach((element) => {
		const tabs = Array.from(element.querySelectorAll<HTMLElement>('ul.tabs > li,ul.link-groups > li')).map((tab) => {
			const linkCards: CardProps[] = Array.from(tab.querySelectorAll<HTMLAnchorElement>('li > a'))
				.filter((link) => !link.closest('.tab-body') && !link.closest('.modal-content'))
				.map((link) => ({
					type: 'link',
					title: ((link.href.indexOf('modal-content-link') !== -1)? link.title:link.textContent) ?? '',
					url: link.href ?? '',
					isDownload: link.dataset.isDownload == 'true',
					modalContent: link.querySelector('.modal-content')?.innerHTML,
					icon: link.dataset.shIcon ?? link.dataset.icon,
					colour: link.dataset.shColour ?? link.dataset.colour,
					linkLabel: queryTextContent('a', link),
					body: queryTextContent('p', link),
				}))

			const articleCards: CardProps[] = Array.from(tab.querySelectorAll<HTMLDivElement>('li > article:not(.left-border-card)')).map((card) => ({
				url: queryHref('a', card),
				title: queryTextContent('h3', card),
				body: queryTextContent('p', card),
				type: 'general',
				linkLabel: card.getAttribute('link-label') ?? queryTextContent('a', card),
				modalContent: card.querySelector('.modal-content')?.innerHTML,
			}))

			const leftBorderCards: CardProps[] = Array.from(tab.querySelectorAll<HTMLDivElement>('li > article.left-border-card')).map((card) => ({
				url: queryHref('a', card),
				title: queryTextContent('h5', card),
				body: queryTextContent('p', card),
				type: 'left-border',
				linkLabel: queryTextContent('a', card),
				modalContent: card.querySelector('.modal-content')?.innerHTML,
			}))

			const titleElement = tab.querySelector('h3,h4,h5,h6')
			const shortTitle = titleElement?.getAttribute('data-short-label')

			return {
				title: queryTextContent('h3,h4,h5,h6', tab),
				shortTitle: shortTitle || undefined,
				description: queryTextContent('p.description', tab),
				cards: [...linkCards, ...articleCards, ...leftBorderCards],
				paragraphs: Array.from(tab.querySelectorAll<HTMLAnchorElement>(':scope > p:not(.description)')).map((p) => p.innerHTML),
				body: tab.querySelector(':scope > .tab-body')?.innerHTML,
			}
		})
		let defaultIndex = 0
		const tabUrlParam = getUrlParam('vtab')
		if (tabUrlParam != null && tabUrlParam.length > 0) {
			defaultIndex = Math.max(
				tabs.findIndex((tab) => tab.title === decodeURIComponent(tabUrlParam.replaceAll('_', ' '))),
				0,
			)
		}
		const props: VerticalTabsProps = {
			heading: queryTextContent(':scope > h2, :scope > h3', element),
			backgroundColour: 'white',
			verticalSpacing: 'default',
			inlineHeaderHtml: element.querySelector(':scope > .inline-header')?.innerHTML,
			isLegacy: false,
			boarderUnderHeading: element.dataset.underlineHeading != 'false',
			tabs: tabs,
			defaultIndex,
		}

		render(<VerticalTabs {...props} />, element)
	})
	document.querySelectorAll<HTMLElement>(':not(.grants-library-search) > .layout-sidebar.sidebar-tabs').forEach((element) => {
		const innerClone = document.createElement('div')
		innerClone.innerHTML = element.querySelector(':scope > * > .layout-sidebar__side__inner')?.innerHTML ?? ''
		innerClone.removeChild(innerClone.querySelector('ul') ?? document.createElement('div'))
		const innerWithoutListHTML = innerClone?.innerHTML

		function getQuerySafeHashFromAnchorHref(element: HTMLAnchorElement) {
			const hrefAsAttribute = element.getAttribute('href') ?? ''
			return '#' + CSS.escape(hrefAsAttribute.substring(1))
		}

		const props: VerticalTabsProps = {
			isLegacy: true,
			inlineHeaderHtml: innerWithoutListHTML,
			backgroundColour: 'white',
			tabs: Array.from(element.querySelectorAll<HTMLAnchorElement>('ul.sidebar-tabs__list > li > a')).map((tab) => ({
				title: tab.textContent ?? undefined,
				body: element.querySelector('.layout-sidebar__main.sidebar-tabs__panels ' + getQuerySafeHashFromAnchorHref(tab))?.innerHTML ?? undefined,
			})),
		}
		render(<VerticalTabs {...props} />, element)
	})

	document.querySelectorAll<HTMLElement>('.staff-ui-tabs-vertical').forEach((element) => {
		const sectionProps = getSectionProps(element)
		const tabs = Array.from(element.querySelectorAll<HTMLAnchorElement>('.tab')).map((tab) => ({
			title: queryTextContent('.title', tab),
			body: tab.querySelector('.body')?.innerHTML,
		}))
		let defaultIndex = 0
		const tabUrlParam = getUrlParam('vtab')
		if (tabUrlParam != null && tabUrlParam.length > 0) {
			defaultIndex = Math.max(
				tabs.findIndex((tab) => tab.title === decodeURIComponent(tabUrlParam.replaceAll('_', ' '))),
				0,
			)
		}
		const props: VerticalTabsProps = {
			...sectionProps,
			headerInWithTabs: element.dataset.headerInWithTabs === 'true',
			tabs: tabs,
			defaultIndex,
		}
		render(<VerticalTabs {...props} />, element)
	})
}

export function VerticalTabs({inlineHeaderHtml, isLegacy, tabs, ...props}: VerticalTabsProps) {
	const [currentIndex, setCurrentIndex] = useState(props.defaultIndex ?? 0)

	const changeTab = (index: number) => {
		setCurrentIndex(index)
		const tab = tabs[index]
		if (tab != null && tab.title != null) {
			const tabUrlParam = encodeURIComponent(tab.title.replaceAll(' ', '_'))
			setUrlParam('vtab', tabUrlParam)
		}
	}

	return (
		<SectionWrapper
			{...props}
			dontShowHeading={props.headerInWithTabs === true}
		>
			<Tab.Group
				selectedIndex={currentIndex}
				onChange={changeTab}
				vertical
			>
				<div className="grid auto-rows-min grid-cols-1 gap-y-[24px] md:grid-cols-2 md:gap-x-[24px] lg:grid-cols-3">
					{/* Mobile */}
					<div className="w-full md:hidden">
						{inlineHeaderHtml != null && (
							<div
								className="vertical-tabs-inline-header"
								dangerouslySetInnerHTML={{__html: inlineHeaderHtml}}
							/>
						)}
						<label
							htmlFor="tabs"
							className="sr-only"
						>
							Select a tab
						</label>
						<Select
							options={tabs.map((tab) => tab.title ?? '').filter((value) => value.length > 0)}
							onChangeIndex={(index) => (index != null ? setCurrentIndex(index) : 0)}
							currentIndex={currentIndex}
						/>
					</div>

					{/* Desktop */}
					<Tab.List
						className="flex hidden max-w-[282px] flex-col space-y-[4px] md:block"
						aria-label="Tabs"
					>
						{props.headerInWithTabs && (
							<>
								<h2 className="text-2xl font-semibold leading-8 text-gray-900">{props.heading}</h2>
								{props.subheading && (
									<div
										className="text-base font-normal leading-6 text-gray-600"
										dangerouslySetInnerHTML={{__html: props.subheading}}
									/>
								)}
							</>
						)}

						{inlineHeaderHtml != null && (
							<div
								className="vertical-tabs-inline-header"
								dangerouslySetInnerHTML={{__html: inlineHeaderHtml}}
							/>
						)}
						{tabs.map((tab, index) => (
							<Tab
								key={tab.title}
								as={Fragment}
							>
								{({selected}) => (
									<button
										className={cx(
											selected ? 'bg-blue-100 text-blue-900 hover:border-blue-700 focus:border-blue-700' : 'text-gray-600 hover:bg-gray-50 focus:bg-gray-50',
											'--overflow-hidden --overflow-ellipsis --whitespace-nowrap block w-[282px] rounded-md border-2 border-transparent px-[16px] py-[9px] text-left align-middle text-sm font-medium focus:outline-none',
										)}
										aria-current={index === currentIndex}
									>
										{tab.shortTitle || tab.title}
									</button>
								)}
							</Tab>
						))}
					</Tab.List>

					{/* Tabs */}

					<Tab.Panels as={Fragment}>
						{tabs.map((tab, index) => (
							<Tab.Panel
								key={index}
								unmount={false}
								className={cx('pb-[20px] pr-[5px] lg:col-span-2')}
							>
								{!isLegacy && <h3 className="block-uplift block text-lg font-semibold leading-6 text-gray-900">{tab.title}</h3>}
								{tab.description != null && <div className="pt-[13px] text-base font-normal leading-6">{tab.description}</div>}
								<div className={cx(!isLegacy ? '!mt-[24px]' : '', 'grid grid-cols-1 gap-y-[24px] md:gap-x-[24px] lg:grid-cols-2')}>
									{tab?.cards?.map((cardData, index) => (
										<Card
											key={index}
											{...cardData}
										/>
									))}
									{tab?.paragraphs?.map((paragraph) => (
										<p
											key={paragraph}
											className="col-span-2 text-base font-normal leading-6"
											dangerouslySetInnerHTML={{__html: paragraph}}
										/>
									))}
									{tab.body != null && (
										<div
											className="tab-body-container col-span-2 text-base font-normal leading-6"
											dangerouslySetInnerHTML={{__html: tab.body}}
										/>
									)}
								</div>
							</Tab.Panel>
						))}
					</Tab.Panels>
				</div>
			</Tab.Group>
		</SectionWrapper>
	)
}
