import React, {useEffect} from 'react'
import {useNewsRecord} from './NewsApi'
import {render} from '~/utils/render'
import { ArticleCategory, Faculty } from './NewsRecordsPage'

export const initNewsDetails = () => {
	document.querySelectorAll('#news-details').forEach((element) => {
		render(<NewsDetailsPage />, element)
	})
}

const NewsDetailsPage = () => {
	const queryParams = new URLSearchParams(window.location.search)
	const id = queryParams.get('id')

	const {newsItem, isLoading, error} = useNewsRecord(id)

	const config = (window as any).newsItemConfig

	const getFacultyTagName = (facultyId: string | null): string | null => {
		return facultyId ? config.faculty.find((faculty: Faculty) => faculty.id === facultyId)?.tag_name || facultyId : null
	}

	const getCategoryTagName = (categoryId: string): string => {
		return config.article_category.find((category: ArticleCategory) => category.id === categoryId)?.tag_name || categoryId
	}

	useEffect(() => {
		if (newsItem) {
			const defaultTitle = document.querySelector('h1')
			if (defaultTitle) {
				defaultTitle.textContent = newsItem.news_headline
			}
		}
	}, [newsItem])

	if (isLoading) return <div>Loading...</div>
	if (error) return <div>Error: {error}</div>
	if (!newsItem) return <div>No news item found</div>

	const formattedDate = new Date(newsItem.published_date).toLocaleDateString('en-AU', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	})

	return (
		<div className="grid grid-cols-1 gap-12 md:grid-cols-3">
			{/* Left Side: News Details */}
			<div className="md:col-span-2">
				{newsItem.file_upload && (
					<img
						src={newsItem.file_upload}
						alt={newsItem.file_description || "News image"}
						className="mb-6 ml-0 mt-6 block h-[350px] w-auto"
					/>
				)}

				<div
					className="mb-6 leading-relaxed text-gray-700"
					dangerouslySetInnerHTML={{__html: newsItem.detailed_news_story}}
				/>
			</div>

			{/* Right Side: Metadata */}
			<div className="p-4 md:col-span-1">
				<h3 className="text-lg font-semibold">Published</h3>
				<div className="mb-8">
					<span className="rounded-[3px] bg-gray-100 px-3 py-[4px] text-gray-700">{formattedDate}</span>
				</div>

				<h3 className="text-lg font-semibold">Author</h3>
				<div className="mb-8">
					<span className="rounded-[3px] bg-gray-100 px-3 py-[4px] text-gray-700">{newsItem.author_name ?? 'N/A'}</span>
				</div>

				<h3 className="text-lg font-semibold">Faculties</h3>
				<div className="mb-8 flex flex-wrap gap-2">
					{newsItem.faculty_1 && <span className="rounded-[3px] bg-pink-100 px-3 py-[4px] text-pink-800">{getFacultyTagName(newsItem.faculty_1)}</span>}
					{newsItem.faculty_2 && <span className="rounded-[3px] bg-pink-100 px-3 py-[4px] text-pink-800">{getFacultyTagName(newsItem.faculty_2)}</span>}
					{newsItem.faculty_3 && <span className="rounded-[3px] bg-pink-100 px-3 py-[4px] text-pink-800">{getFacultyTagName(newsItem.faculty_3)}</span>}
				</div>

				<h3 className="text-lg font-semibold">Categories</h3>
				<div className="mb-8 flex flex-wrap gap-2">
					{newsItem.category_1 && newsItem.category_1 !== 'uncategorised' && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{getCategoryTagName(newsItem.category_1)}</span>}
					{newsItem.category_2 && newsItem.category_2 !== 'uncategorised' && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{getCategoryTagName(newsItem.category_2)}</span>}
					{newsItem.category_3 && newsItem.category_3 !== 'uncategorised' && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{getCategoryTagName(newsItem.category_3)}</span>}
					{/* {newsItem.article_topic_2 && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{newsItem.article_topic_2}</span>} */}
				</div>

				{/* <h3 className="text-lg font-semibold">Type</h3>
				<div className="flex flex-wrap gap-2">
					{newsItem.article_type_1 && <span className="rounded-[3px] bg-orange-100 px-3 py-[4px] text-orange-800">{newsItem.article_type_1}</span>}
					{newsItem.article_type_2 && <span className="rounded-[3px] bg-orange-100 px-3 py-[4px] text-orange-800">{newsItem.article_type_2}</span>}
				</div> */}
			</div>
		</div>
	)
}

export default NewsDetailsPage
