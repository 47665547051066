import React from 'react'
import {Icon} from '~/blocks/Icon'
import type {NewsItem} from './NewsApi'
import {cx} from '~/utils/utils'
import type {Faculty, ArticleCategory} from './NewsRecordsPage'

interface NewsRecordCardProps {
	newsItem: NewsItem
	isGridView: boolean
	className?: string
}

export function NewsRecordCard({newsItem, isGridView, className}: NewsRecordCardProps) {
	const formattedDate = new Date(newsItem.published_date).toLocaleDateString('en-AU', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	})

	const getMainDomain = (url: string | null): string | null => {
		if (!url) return null
		try {
			return new URL(url).hostname
		} catch {
			return null
		}
	}

	const config = (window as any).newsRecordsConfig

	const getFacultyTagName = (facultyId: string | null): string | null => {
		return facultyId ? config.faculty.find((faculty: Faculty) => faculty.id === facultyId)?.tag_name || facultyId : null
	}

	const getCategoryTagName = (categoryId: string): string => {
		return config.article_category.find((category: ArticleCategory) => category.id === categoryId)?.tag_name || categoryId
	}

	const domain = getMainDomain(newsItem.existing_page_url)

	const handleClick = () => {
		const config = (window as any).newsRecordsConfig;
	
		if (newsItem.link_info === 'Use the contact email' && newsItem.published_contact_email) {
			window.location.href = `mailto:${newsItem.published_contact_email}`;
		} else if (newsItem.detailed_news_story) {
			window.location.href = `${config.newsDetailsUrl}?id=${newsItem.id}`;
		} else if (newsItem.existing_page_url) {
			window.location.href = newsItem.existing_page_url;
		}
	};	

	return (
		<div
			className={cx('group relative flex flex-col overflow-hidden bg-white p-0 text-left', isGridView ? 'm-3 rounded-lg border border-gray-100 shadow hover:shadow-lg' : '', className)}
			onClick={handleClick}
		>
			{isGridView ? (
				<GridView
					newsItem={newsItem}
					formattedDate={formattedDate}
					domain={domain}
					onClick={handleClick}
					getFacultyTagName={getFacultyTagName}
					getCategoryTagName={getCategoryTagName}
				/>
			) : (
				<ListView
					newsItem={newsItem}
					formattedDate={formattedDate}
					domain={domain}
					onClick={handleClick}
					getFacultyTagName={getFacultyTagName}
					getCategoryTagName={getCategoryTagName}
				/>
			)}
		</div>
	)
}

interface GridViewProps {
	newsItem: NewsItem
	formattedDate: string
	domain: string | null
	onClick: () => void
	getFacultyTagName: (facultyId: string | null) => string | null
	getCategoryTagName: (categoryId: string) => string
}

interface ListViewProps {
	newsItem: NewsItem
	formattedDate: string
	domain: string | null
	onClick: () => void
	getFacultyTagName: (facultyId: string | null) => string | null
	getCategoryTagName: (categoryId: string) => string
}


function GridView({newsItem, formattedDate, domain, onClick, getFacultyTagName, getCategoryTagName}: GridViewProps) {
	return (
		<div className="flex flex-col p-6">
			<a
				href="#"
				onClick={(e) => {
					e.preventDefault()
					onClick()
				}}
				className="absolute right-6 top-6"
				aria-label="External Link"
			>
				<div className="flex-shrink-0">
					<Icon
						icon="external-link"
						className="h-5 w-5 text-blue-700"
						aria-hidden="true"
					/>
				</div>
			</a>

			<div className="mb-1 flex flex-wrap items-center text-sm text-gray-700">
				<span className="mb-2 me-2 rounded-md bg-gray-100 px-2 py-1 text-gray-700">{formattedDate}</span>
				{newsItem.link_info === 'Use the contact email' && newsItem.published_contact_email ? (
					<span className="mb-2 rounded-md bg-gray-100 px-2 py-1 text-gray-700">
						{newsItem.published_contact_email}
					</span>
				) : newsItem.detailed_news_story ? (
					<span className="mb-2 rounded-md bg-gray-100 px-2 py-1 text-gray-700">staff.unimelb.edu.au</span>
				) : (
					domain && <span className="mb-2 rounded-md bg-gray-100 px-2 py-1 text-gray-700">{domain}</span>
				)}
			</div>

			{newsItem.file_upload && (
				<img
					className="mb-5 mt-2 h-56 w-full object-contain"
					src={newsItem.file_upload}
					alt={newsItem.file_description || "News image"}
					aria-hidden="true"
				/>
			)}

			<a
				href="#"
				onClick={(e) => {
					e.preventDefault()
					onClick()
				}}
				className="mb-2 text-xl font-semibold text-blue-700 underline"
			>
				{newsItem.news_headline}
			</a>

			<div className="mb-4 text-gray-700">{newsItem.brief_text}</div>

			<div className="text-sm font-medium text-gray-700">
				<div className="mb-4 flex flex-wrap gap-2">
					{newsItem.faculty_1 && <span className="rounded-[3px] bg-pink-100 px-3 py-[4px] text-pink-800">{getFacultyTagName(newsItem.faculty_1)}</span>}
					{newsItem.faculty_2 && <span className="rounded-[3px] bg-pink-100 px-3 py-[4px] text-pink-800">{getFacultyTagName(newsItem.faculty_2)}</span>}
					{newsItem.faculty_3 && <span className="rounded-[3px] bg-pink-100 px-3 py-[4px] text-pink-800">{getFacultyTagName(newsItem.faculty_3)}</span>}
					{newsItem.category_1 && newsItem.category_1 !== 'uncategorised' && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{getCategoryTagName(newsItem.category_1)}</span>}
					{newsItem.category_2 && newsItem.category_2 !== 'uncategorised' && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{getCategoryTagName(newsItem.category_2)}</span>}
					{newsItem.category_3 && newsItem.category_3 !== 'uncategorised' && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{getCategoryTagName(newsItem.category_3)}</span>}
					{/* {newsItem.article_topic_2 && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{newsItem.article_topic_2}</span>}
					{newsItem.article_type_1 && <span className="rounded-[3px] bg-orange-100 px-3 py-[4px] text-orange-800">{newsItem.article_type_1}</span>}
					{newsItem.article_type_2 && <span className="rounded-[3px] bg-orange-100 px-3 py-[4px] text-orange-800">{newsItem.article_type_2}</span>} */}
				</div>
			</div>
		</div>
	)
}

function ListView({newsItem, formattedDate, domain, onClick, getFacultyTagName, getCategoryTagName}: ListViewProps) {
	return (
		<div className="mb-6 flex flex-col p-6 md:flex-row">
			{newsItem.file_upload && (
				<img
					className="mb-4 h-56 w-full object-contain md:mb-0 md:mr-4 md:h-full md:w-60"
					src={newsItem.file_upload}
					alt={newsItem.file_description || "News image"}
					aria-hidden="true"
				/>
			)}
			<div className="flex flex-col">
				<a
					href="#"
					onClick={(e) => {
						e.preventDefault()
						onClick()
					}}
					className="mb-3 flex items-center text-xl font-semibold text-blue-700 underline"
				>
					{newsItem.news_headline}
					<div className="ml-2 flex-shrink-0">
						<Icon
							icon="external-link"
							className="h-5 w-5 text-blue-700"
							aria-hidden="true"
						/>
					</div>
				</a>

				<div className="mb-1 flex flex-wrap items-center text-sm text-gray-700">
					<span className="mb-2 me-2 rounded-md bg-gray-100 px-2 py-1 text-gray-700">{formattedDate}</span>
					{newsItem.link_info === 'Use the contact email' && newsItem.published_contact_email ? (
						<span className="mb-2 rounded-md bg-gray-100 px-2 py-1 text-gray-700">
							{newsItem.published_contact_email}
						</span>
					) : newsItem.detailed_news_story ? (
						<span className="mb-2 rounded-md bg-gray-100 px-2 py-1 text-gray-700">staff.unimelb.edu.au</span>
					) : (
						domain && <span className="mb-2 rounded-md bg-gray-100 px-2 py-1 text-gray-700">{domain}</span>
					)}
				</div>
				<div className="mb-4 text-gray-700">{newsItem.brief_text}</div>

				<div className="text-sm font-medium text-gray-700">
					<div className="flex flex-wrap items-center gap-2">
						{newsItem.faculty_1 && <span className="rounded-[3px] bg-pink-100 px-3 py-[4px] text-pink-800">{getFacultyTagName(newsItem.faculty_1)}</span>}
						{newsItem.faculty_2 && <span className="rounded-[3px] bg-pink-100 px-3 py-[4px] text-pink-800">{getFacultyTagName(newsItem.faculty_2)}</span>}
						{newsItem.faculty_3 && <span className="rounded-[3px] bg-pink-100 px-3 py-[4px] text-pink-800">{getFacultyTagName(newsItem.faculty_3)}</span>}
						{newsItem.category_1 && newsItem.category_1 !== 'uncategorised' && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{getCategoryTagName(newsItem.category_1)}</span>}
						{newsItem.category_2 && newsItem.category_2 !== 'uncategorised' && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{getCategoryTagName(newsItem.category_2)}</span>}
						{newsItem.category_3 && newsItem.category_3 !== 'uncategorised' && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{getCategoryTagName(newsItem.category_3)}</span>}
						{/* {newsItem.article_topic_2 && <span className="rounded-[3px] bg-blue-100 px-3 py-[4px] text-blue-800">{newsItem.article_topic_2}</span>} */}
						{/* {newsItem.article_type_1 && <span className="rounded-[3px] bg-orange-100 px-3 py-[4px] text-orange-800">{newsItem.article_type_1}</span>}
						{newsItem.article_type_2 && <span className="rounded-[3px] bg-orange-100 px-3 py-[4px] text-orange-800">{newsItem.article_type_2}</span>} */}
					</div>
				</div>
			</div>
		</div>
	)
}
